import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
import { Flex, Box, Heading, Button, Link } from "@chakra-ui/react"
import Highlight from "../highlight"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "796px" },
    width: "100%",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "heading-box": {
    margin: "0 auto",
    mt: { base: "51px", md: "51px", lg: "120px" },
    px: { base: "0px", md: "0px", lg: "114px" },
    maxWidth: { base: "100%", md: "100%", lg: "1349px" },
    justifyContent: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#1B263F",
    textAlign: "center",
  },
  "inner-box": {
    margin: "0 auto",
    mt: "40px",
    mb: { base: "60px", md: "60px", lg: "0px" },
    px: { base: "0px", md: "0px", lg: "114px" },
    maxWidth: { base: "100%", md: "100%", lg: "1349px" },
    height: { base: "100%", md: "100%", lg: "468px" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    justifyContent: {
      base: "flex-start",
      md: "flex-start",
      lg: "space-between",
    },
  },
  hero: {
    width: { base: "100%", md: "100%", lg: "430px" },
    height: { base: "auto", md: "auto", lg: "271.63px" },
  },
  "left-box": {
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "658px" },
  },
  button: {
    mt: { base: "16px", md: "16px", lg: "40px" },
    bg: "#129459",
    padding: "8px 16px",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: { base: "16px", md: "16px", lg: "18px" },
    lineHeight: { base: "150%", md: "150%", lg: "24px" },
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  highlight: {
    root: { mt: "16px" },
  },
  logos: {
    width: "100%",
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: "space-between",
    alignItems: "center",
    mt: { base: "16px", md: "16px", lg: "40px" },
  },
  logo: {
    mt: { base: "24px", md: "24px", lg: "0px" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "84px",
  },
}

const navigateToPublications = () => navigate("/publications")

const SectionThoughtLeaders = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>
        We are Healthcare Data Analytics Experts
      </Heading>
    </Flex>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Highlight
          text="44 published articles in the top business and medical journals"
          styles={styles.highlight}
          Image={() => (
            <StaticImage
              src="../../images/homepage/writing.png"
              alt="Checkmark"
              placeholder="blurred"
            />
          )}
        />
        <Highlight
          text="Co-authored 11 articles in Harvard Business Review since 2014"
          styles={styles.highlight}
          Image={() => (
            <StaticImage
              src="../../images/homepage/writing.png"
              alt="Writing utensil"
              placeholder="blurred"
            />
          )}
        />
        <Highlight
          text="40+ blog posts published around value-based healthcare topics"
          styles={styles.highlight}
          Image={() => (
            <StaticImage
              src="../../images/homepage/writing.png"
              alt="Writing utensil"
              placeholder="blurred"
            />
          )}
        />
        <Flex sx={styles.logos}>
          <Box sx={styles.logo}>
            <StaticImage
              src="../../images/homepage/harvard.png"
              alt="Harvard logo"
              placeholder="blurred"
              style={{ width: "189.23px", height: "84px" }}
            />
          </Box>
          <Box sx={styles.logo}>
            <StaticImage
              src="../../images/homepage/nejm.png"
              alt="NEJM logo"
              placeholder="blurred"
              style={{ width: "84px", height: "84px" }}
            />
          </Box>
          <Box sx={styles.logo}>
            <StaticImage
              src="../../images/homepage/jama.png"
              alt="JAMA logo"
              placeholder="blurred"
              style={{ width: "58.5px", height: "84px" }}
            />
          </Box>
        </Flex>
        <Button
          sx={styles.button}
          title="Click here to review our library of healthcare data analytics research."
          onClick={navigateToPublications}
        >
          Access Our Research Now
        </Button>
      </Flex>
      <Box sx={styles.hero}>
        <Link as={GatsbyLink} to={"/publications"}>
          <StaticImage
            src="../../images/homepage/hp-hero-d.png"
            alt="Image of a healthcare administrator and physicians. Click here to review our library of healthcare data analytics research."
            placeholder="blurred"
          />
        </Link>
      </Box>
    </Flex>
  </Box>
)

export default SectionThoughtLeaders
