import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Heading, Box, Button } from "@chakra-ui/react"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "627px" },
    width: "100%",
    px: "24px",
  },
  "inner-box": {
    width: "100%",
    height: "100%",
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
    justifyContent: { base: "center", md: "center", lg: "space-around" },
    mt: { base: "40px", md: "40px", lg: "0px" },
    mb: { base: "32px", md: "32px", lg: "0px" },
  },
  "left-box": {
    maxWidth: { base: "311px", md: "311px", lg: "542px" },
    flexDirection: "column",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: "space-between",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  image: {
    mt: { base: "48px", md: "48px", lg: "0px" },
    height: { base: "240px", md: "240px", lg: "499px" },
    maxWidth: { base: "203.16px", md: "203.16px", lg: "422px" },
  },
  button: {
    mt: "36px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "159px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us")

const SectionBlueDoctor = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles.heading}>
          Find out why everyone from New England Baptist to Hershey Medical
          Center to Gundersen Health System all rely on Avant-garde Health.
        </Heading>
        <Button sx={styles.button} onClick={navigateToContactUs}>
          Contact Us!
        </Button>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/homepage/homepage-dr.png"
          alt="Home page doctor"
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionBlueDoctor
