import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Box, Heading, Button, Link } from "@chakra-ui/react"
import Highlight from "../highlight"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "760px" },
    width: "100%",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "inner-box": {
    margin: "0 auto",
    mt: "0px",
    mb: { base: "32px", md: "32px", lg: "0px" },
    maxWidth: { base: "100%", md: "100%", lg: "1339px" },
    height: { base: "100%", md: "100%", lg: "520px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "flex-start", md: "flex-start", lg: "center" },
    justifyContent: "space-between",
  },
  hero: {
    width: { base: "100%", md: "100%", lg: "657px" },
    height: { base: "auto", md: "auto", lg: "377px" },
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#1B263F",
    textAlign: "center",
    width: { base: "100%", md: "100%", lg: "719px" },

    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "64px" },
    mb: { base: "32px", md: "32px", lg: "0px" },
  },
  "right-box": {
    mt: { base: "40px", md: "40px", lg: "0px" },
    flexDirection: "column",
    width: { base: "100%", md: "100%", lg: "658px" },
  },
  button: {
    mt: { base: "24px", md: "24px", lg: "16px" },
    bg: "#129459",
    padding: "8px 16px",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: { base: "16px", md: "16px", lg: "18px" },
    lineHeight: { base: "150%", md: "150%", lg: "24px" },
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  highlight: {
    root: {
      mt: "16px",
      "&:nth-child(2)": {
        mt: { base: "24px", md: "24px", lg: "16px" },
      },
    },
  },
}

const SectionResults = () => (
  <Box sx={styles.root}>
    <Heading sx={styles.heading}>
      Our clients achieve measurable results. We can help you do that too!
    </Heading>
    <Flex sx={styles["inner-box"]}>
      <Link as={GatsbyLink} to={"/hershey-medical-center-case-study"}>
        <Box sx={styles.hero}>
          <StaticImage
            src="../../images/homepage/hp-hero-c.jpeg"
            alt="Illustration of a group of people looking at an oversized healthcare checklist on a clipboard in front of a blue background. Click here to learn how hospitals, ASCs, and physicians use our healthcare data analytics platform to reduce costs and improve patient outcomes."
            placeholder="blurred"
          />
        </Box>
      </Link>
      <Flex sx={styles["right-box"]}>
        <Highlight
          text="$2,300 reduction in average cost per case"
          styles={styles.highlight}
        />
        <Highlight
          text="33% reduction in discharges to SNFs, Inpatient Rehab, etc."
          styles={styles.highlight}
        />
        <Highlight
          text="15% decrease in 90-day readmission rates"
          styles={styles.highlight}
        />
        <Highlight
          text="13% reduction in average length of stay"
          styles={styles.highlight}
        />
        <Link
          as={GatsbyLink}
          to={"/hershey-medical-center-case-study"}
          title="Click here to learn how hospitals, ASCs and physicians use our healthcare data analytics platform to reduce costs and improve patient outcomes."
        >
          <Button sx={styles.button}>
            See How Our Clients Achieve Success{" "}
          </Button>
        </Link>
      </Flex>
    </Flex>
  </Box>
)

export default SectionResults
