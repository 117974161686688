import React from "react"
import { Flex, Box } from "@chakra-ui/react"
import merge from "lodash/merge"
import { StaticImage } from "gatsby-plugin-image"

const defaultStyles = {
  root: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "430px",
    height: "440px",
  },
  icon: {
    height: "203px",
    width: "203px",
    bg: "#9CACBC",
    borderRadius: "50%",
  },
}

const Card = ({ Image, children, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <Box sx={_styles.icon}>
        <Image />
      </Box>
      {children}
    </Flex>
  )
}

Card.defaultProps = {
  Image: () => (
    <StaticImage
      src="../images/homepage/persona-a.png"
      alt="Physician"
      placeholder="blurred"
    />
  ),
  styles: {},
}

export default Card
