import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    flexDirection: { base: "column", md: "column", lg: "row" },
    height: { base: "100%", md: "100%", lg: "360px" },
    alignItems: "center",
    px: "24px",
    justifyContent: "center",
    width: "100%",
    bg: "#ECEFF7",
  },
  image: {
    mt: { base: "40px", md: "40px", lg: "0px" },
    minWidth: "160px",
    minHeight: "160px",
    width: "160px",
    height: "160px",
  },
  "text-box": {
    mt: { base: "40px", md: "40px", lg: "0px" },
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "656px" },
    ml: { base: "0px", md: "0px", lg: "46px" },
    mb: { base: "80px", md: "80px", lg: "0px" },
  },
  text: {
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
}

const SectionQuote = () => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/homepage/chip.png"
          alt="Photo of doctor"
          placeholder="blurred"
        />
      </Box>
      <Flex sx={styles["text-box"]}>
        <Text sx={styles.text}>
          "The data analytics have been tremendous for us by providing a better understanding of our actual costs."
        </Text>
        <Text sx={styles.text} mt="24px">
          – Charles Davis, MD, PhD
        </Text>
        <Text sx={styles.text} mt="24px">
          Chief of Arthroplasty, Penn State Health Milton S. Hershey Medical Center
        </Text>
      </Flex>
    </Flex>
  )
}

export default SectionQuote
