import React from "react"
import Video from "../video"
import { Box } from "@chakra-ui/react"

const styles = {
  root: {
    height: { base: "418px", md: "418px", lg: "815px" },
    width: "100%",
    display: { base: "flex", md: "flex", lg: "block" },
    flexDirection: "column",
    justifyContent: "center",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  video: {
    margin: "0 auto",
    mt: { base: "0px", md: "0px", lg: "160px" },
    maxWidth: "883px",
    width: "100%",
    height: { base: "auto", md: "auto", lg: "494.56px" },
  },
}

const SectionVideo = () => (
  <Box sx={styles.root}>
    <Box sx={styles.video}>
      <Video video="https://player.vimeo.com/video/764943714?h=3ff7b3d987" />
    </Box>
  </Box>
)

export default SectionVideo
