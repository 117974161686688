import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Box,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"
import Card from "../card"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "827px" },
    width: "100%",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "heading-box": {
    margin: "0 auto",
    mt: { base: "0px", md: "0px", lg: "40px" },
    maxWidth: { base: "100%", md: "100%", lg: "1100px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "heading-one": {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#1B263F",
    textAlign: "center",
  },
  "heading-two": {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    color: "#595C5F",
    textAlign: "center",
  },
  cards: {
    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "80px" },
    mb: { base: "80px", md: "80px", lg: "0px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: "space-between",
    maxWidth: { base: "100%", md: "100%", lg: "1400px" },
    height: { base: "100%", md: "100%", lg: "459px" },
  },
  card: {
    icon: { padding: "25px" },
  },
  "card-inner-box": {
    flexDirection: "column",
    mt: "40px",
    width: "auto",
  },
  "card-title": {
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    color: "#041424",
    textAlign: "center",
  },
  "card-text": {
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  ul: {
    listStylePosition: "outside",
    paddingLeft: "10px",
  },
}

const SectionWeHelp = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["heading-box"]}>
      <Heading
        sx={styles["heading-one"]}
        display={{ base: "block", md: "block", lg: "none" }}
      >
        We Help
      </Heading>
      <Heading
        sx={styles["heading-one"]}
        display={{ base: "none", md: "none", lg: "block" }}
      >
        When your people don’t have all the details, it’s easy to draw the wrong
        conclusions
      </Heading>
      <Heading
        sx={styles["heading-two"]}
        mt="24px"
        display={{ base: "none", md: "none", lg: "block" }}
      >
        We help everyone take a closer look…
      </Heading>
    </Flex>
    <Flex sx={styles.cards}>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/homepage/persona-a.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["card-inner-box"]}>
          <Text sx={styles["card-title"]}>Hospital Leadership</Text>
          <Text sx={styles["card-text"]}>rely on us to improve</Text>
          <UnorderedList sx={styles.ul}>
            <ListItem>Organizational savings</ListItem>
            <ListItem>Outcomes & results</ListItem>
            <ListItem>Data-driven decisions</ListItem>
            <ListItem>Revenue & profitability</ListItem>
          </UnorderedList>
        </Flex>
      </Card>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/homepage/persona-b.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["card-inner-box"]}>
          <Text sx={styles["card-title"]}>Service Line Administrators</Text>
          <Text sx={styles["card-text"]}>rely on us to improve</Text>
          <UnorderedList sx={styles.ul}>
            <ListItem>Operational efficiencies</ListItem>
            <ListItem>Performance benchmarking</ListItem>
            <ListItem>Physician engagement </ListItem>
            <ListItem>Alignment with organizational goals</ListItem>
          </UnorderedList>
        </Flex>
      </Card>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/homepage/persona-c.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["card-inner-box"]}>
          <Text sx={styles["card-title"]}>Physicians and Surgeons</Text>
          <Text sx={styles["card-text"]}>rely on us to improve</Text>
          <UnorderedList sx={styles.ul} width="250px" height="100%">
            <ListItem>Management of care delivery</ListItem>
            <ListItem>Benchmarking against peers and top performers</ListItem>
            <ListItem>
              OR efficiency, length of stay, readmission rates, etc.
            </ListItem>
            <ListItem>Alignment with organizational goals</ListItem>
          </UnorderedList>
        </Flex>
      </Card>
    </Flex>
  </Box>
)

export default SectionWeHelp
