import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react"
import Card from "../card"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "843px" },
    width: "100%",
    bg: "#ECEFF7",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "heading-box": {
    margin: "0 auto",
    mt: { base: "80px", md: "80px", lg: "120px" },
    maxWidth: { base: "100%", md: "100%", lg: "1237px" },
    justifyContent: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#1B263F",
    textAlign: "center",
  },
  cards: {
    margin: "0 auto",
    mt: { base: "55px", md: "55px", lg: "64px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: "space-between",
    maxWidth: { base: "100%", md: "100%", lg: "1237px" },
    height: { base: "100%", md: "100%", lg: "492px" },
  },
  card: {
    root: {
      alignItems: "flex-start",
      width: { base: "100%", md: "100%", lg: "320px" },
      height: "100%",
      "&:nth-child(2)": {
        mx: { base: "0px", md: "0px", lg: "20px" },
        mt: { base: "100px", md: "100px", lg: "0px" },
      },
      "&:nth-child(3)": {
        my: { base: "100px", md: "100px", lg: "0px" },
      },
    },
    icon: {
      width: "100%",
      height: { base: "100%", md: "100%", lg: "230px" },
      bg: "#F5F5F7",
      borderRadius: "8px",
    },
  },
  "card-text": {
    textAlign: { base: "center", md: "center", lg: "left" },
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  "button-box": {
    display: { base: "none", md: "none", lg: "flex" },
    margin: "0 auto",
    maxWidth: { base: "100%", md: "100%", lg: "1237px" },
    justifyContent: "center",
  },
  button: {
    display: { base: "none", md: "none", lg: "block" },
    bg: "#129459",
    height: "56px",
    width: "431px",
    borderRadius: "4px",
    padding: "16px",
    color: "#F1F1F1",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToCaremeasurement = () => navigate("/caremeasurement")

const SectionHowItWorks = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>
        How our Healthcare Data Analytics platform works
      </Heading>
    </Flex>
    <Flex sx={styles.cards}>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/homepage/how-it-works-a.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Text sx={styles["card-text"]}>
          We ingest EMR, Financial, and PROM data and marry that with claims
          data, benchmarking, and our proprietary algorithms to clean, catalog,
          risk adjust, and automate TDABC (Time-driven activity-based costing).
        </Text>
      </Card>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/homepage/how-it-works-b.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Text sx={styles["card-text"]}>
          With easy-to-use software, evaluate your data & performance,
          understand true costs, identify opportunities to lower supply and
          personnel costs, improve outcomes, and create more efficient
          operations.
        </Text>
      </Card>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/homepage/how-it-works-c.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Text sx={styles["card-text"]}>
          Collaborate with our team to create the plan and execute strategic
          actions to realize the improvement opportunities you’ve identified.
          Track the results over time to make sure that your efforts are
          successful.
        </Text>
      </Card>
    </Flex>
    <Flex sx={styles["button-box"]}>
      <Button
        sx={styles.button}
        title="Click here to learn more about our healthcare data analytics platform."
        onClick={navigateToCaremeasurement}
      >
        Learn More About CareMeasurement
      </Button>
    </Flex>
  </Box>
)

export default SectionHowItWorks
